<template>

  <template v-if="isBootstrapDataLoading || paginationList.isLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <template v-if="paginationList.totalItems === 0">
      There are currently no account requests.
    </template>

    <template v-else>
      <div class="flex items-end mb-4">
        <strong>{{ paginationList.totalItems }} total accounts requests</strong>
        <span class="flex-grow" />
        <button
          @click="exportData"
          class="small tertiary"
          :disabled="!canExportData || isExportingData"
          :title="canExportData ? '' : 'This action is not supported in your browser. Please use Chrome.'"
        >
          <LoadingIcon size="small" class="mr-2" v-if="isExportingData" />
          Export All to CSV...
        </button>
      </div>
      <div class="table-container">
        <LoadingOverlay type="light" v-if="paginationList.isLoading" />
        <div class="table account-requests-table">
          <div class="table-header table-row">
            <div class="timestamp">CREATED AT</div>
            <div>COMPANY NAME</div>
            <div>EMAIL</div>
            <div>STATUS</div>
            <div>ACTIONS</div>
          </div>
          <AccountRequestTableRow
            :key="accountRequest.id"
            :accountRequest="accountRequest"
            v-for="accountRequest in paginationList.currentPage"
          />
        </div>
      </div>
      <PaginationButtons :paginationListName="paginationListName" />
    </template>

  </template>

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'
  import { format, addMinutes } from 'date-fns'

  import useExportAsCSV from '@/composables/useExportAsCSV'
  import usePaginationPolling from '@/composables/usePaginationPolling'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'
  import AccountRequestTableRow from '@/components/tables/AccountRequestTableRow.vue'

  export default {
    components: {
      LoadingIcon,
      LoadingOverlay,
      PaginationButtons,
      AccountRequestTableRow,
    },
    setup() {

      const paginationListName = ref('accountRequests')

      const { exportAsCSV, canExportData, checkIfCanExportData } = useExportAsCSV()

      // @TODO: remove this when socket stuff is available
      const { paginationList } = usePaginationPolling({ paginationListName })

      return {
        exportAsCSV,
        canExportData,
        checkIfCanExportData,

        paginationList,
        paginationListName,
      }

    },
    computed: {
      ...mapState('app', ['isBootstrapDataLoading']),
    },
    data() {
      return {
        isExportingData: false,
      }
    },
    created() {
      this.$store.dispatch('pagination/LOAD_PAGE', { listName: this.paginationListName })
    },
    methods: {
      exportData() {

        if (!this.checkIfCanExportData()) return null

        this.isExportingData = true

        return this.$store.dispatch('pagination/GET_ALL_ITEMS', { listName: this.paginationListName })
          .then((response) => {

            const lines = response.data.map((accountRequest) => {

              // use addMinutes here to add in the UTC offset so the date is in
              //  UTC without an explicit timezone offset in the string
              const date = new Date(accountRequest.insertedAt)
              const formattedDate = format(addMinutes(date, date.getTimezoneOffset()), 'MM/dd/yyyy h:mm a')

              // @NOTE: none of these fields should have double-quotes in
              //  them, but if they did we would need to replace any
              //  instaces of " with ""
              return `"${formattedDate}", "${accountRequest.id}", "${accountRequest.account.email}", "${accountRequest.account.status}"`

            })

            lines.unshift('CREATED AT, INVITE ID, EMAIL, STATUS')

            return this.exportAsCSV('dispatch-account-requests.csv', lines.join('\n'))
              .then((didSaveFile) => {
                if (!didSaveFile) return
                this.$store.dispatch('toast/CREATE_TOAST', {
                  text: 'Successfully exported account requests!',
                  type: 'success',
                })
              })

          })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not export account requests: ${error.message}`,
              type: 'error',
            })
          })
          .finally(() => {
            this.isExportingData = false
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>
