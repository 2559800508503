<template>

  <div class="table-row">
    <div class="timestamp">
      {{ formatTimestamp(accountRequest.insertedAt) }}
    </div>
    <div>
      {{ accountRequest.account.companyName }}
    </div>
    <div>
      {{ accountRequest.account.email }}
    </div>
    <div>
      <span class="status-indicator" :class="[accountRequest.account.status]" />
      {{ status }}
    </div>
    <div class="icons">
      <template v-if="accountRequest.account.status === 'pending'">
        <Tooltip>
          <template #trigger>
            <button
              :disabled="isLoading"
              class="small ghost approve"
              @click="approveAccountRequest"
            >
              <ApproveIcon />
            </button>
          </template>
          Approve Request
        </tooltip>
        <Tooltip>
          <template #trigger>
            <button
              :disabled="isLoading"
              class="small ghost deny"
              @click="denyAccountRequest"
            >
              <DenyIcon />
            </button>
          </template>
          Deny Request
        </tooltip>
      </template>
      <template v-if="accountRequest.account.status === 'invited'">
        <Tooltip>
          <template #trigger>
            <button
              :disabled="isLoading"
              class="small ghost email"
              @click="resendConfirmationEmail"
            >
              <EmailIcon />
            </button>
          </template>
          Resend Invite Email
        </tooltip>

      </template>
    </div>
  </div>

</template>

<script>

  import useFilters from '@/composables/useFilters'

  import Tooltip from '@/components/utils/Tooltip.vue'

  import EmailIcon from '@/assets/icons/email.svg'
  import DenyIcon from '@/assets/icons/times-circle.svg'
  import ApproveIcon from '@/assets/icons/check-circle.svg'

  export default {
    props: {
      accountRequest: {
        type: Object,
        required: true,
      },
    },
    components: {
      Tooltip,
      DenyIcon,
      EmailIcon,
      ApproveIcon,
    },
    setup(props) {
      const { formatTimestamp, titleCase } = useFilters()
      return { formatTimestamp, titleCase }
    },
    computed: {
      status() {
        return this.titleCase(this.accountRequest.account.status)
      },
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      denyAccountRequest() {
        this.isLoading = true
        this.$store.dispatch('api/DENY_ACCOUNT_REQUEST', this.accountRequest.id)
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Successfully denied account request!',
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not deny account request!',
              type: 'error',
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      approveAccountRequest() {
        this.isLoading = true
        this.$store.dispatch('api/APPROVE_ACCOUNT_REQUEST', this.accountRequest.id)
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Successfully approved account request!',
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not approve account request!',
              type: 'error',
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      resendConfirmationEmail() {
        this.isLoading = true
        // @NOTE: approving an account for a second time just re-triggers the
        //  confirmation email
        this.$store.dispatch('api/APPROVE_ACCOUNT_REQUEST', this.accountRequest.id)
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Successfully resent confirmation email!',
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not resend confirmation email!',
              type: 'error',
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .row
    min-height: calc(3.25rem + 1px)

  .status-indicator
    &.denied
      @apply bg-danger-700

    &.active
      @apply bg-blue-500

    &.pending
      @apply bg-warning-500

  .icons
    .approve
      @apply text-success-500

    .deny
      @apply text-danger-500

</style>
